import React, { lazy, Suspense } from "react";
import styles from "./kba-third-fail.styles.css";
import { CpButton, CpIcon } from "canopy-styleguide!sofe";
import PropTypes from "prop-types";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from "single-spa";
import { featureEnabled } from "feature-toggles!sofe";

const UploadFiles = lazy(() => SystemJS.import("docs-ui!sofe").then((m) => m.dropListChunk()));

const UploadWrapper = (props) => {
  if (!featureEnabled("toggle_docs_parcels")) {
    return (
      <Parcel config={props.config} mountParcel={props.mountParcel} uploadFilesAction={props.uploadFilesAction}>
        {props.children}
      </Parcel>
    );
  }
  return (
    <Suspense fallback={null}>
      <UploadFiles uploadFilesAction={props.uploadFilesAction}>{props.children}</UploadFiles>
    </Suspense>
  );
};

export default class UploadFileWell extends React.Component {
  static propTypes = {
    uploadFiles: PropTypes.func,
  };

  clearValue = (e) => {
    e.target.value = null;
  };

  handleFileChange = (e) => {
    let fileList = e.target.files;
    this.onUpload(fileList);
  };

  onDragAndUpload = (e) => {
    this.onUpload(e);
  };

  onUpload = (fileList) => {
    let files = [];
    for (let i = 0; i < fileList.length; i++) files.push(fileList[i]);
    this.props.uploadFiles(files);
  };

  render() {
    return (
      <UploadWrapper
        config={() => SystemJS.import("docs-ui!sofe").then((mod) => mod.loadDropListParcel())}
        uploadFilesAction={this.onDragAndUpload}
        mountParcel={mountRootParcel}
      >
        <div className={`${styles.uploadWell} cps-bg-color-gray5`}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CpIcon name="misc-cloud-up-arrow" fill="var(--cp-color-app-icon)" />
            <span className="cp-body-sm cp-mh-8">Drop signed file here</span>
            <div style={{ textAlign: "center" }}>
              <input
                ref={(ref) => {
                  this.input = ref;
                }}
                type="file"
                name="uploadFile"
                data-testid="uploadFileInput"
                multiple
                style={{ display: "none" }}
                onClick={this.clearValue}
                onChange={this.handleFileChange}
              />
              <CpButton
                onClick={() => {
                  this.input.click();
                }}
                btnType="secondary"
                className="cp-mh-4"
              >
                Choose file
              </CpButton>
            </div>
          </div>
        </div>
      </UploadWrapper>
    );
  }
}
